import React from "react";
import {AuthContext} from "../../../Library/AuthContext";
import AuthenticatedLayout, {AuthenticatedLayoutCustomButton} from "../../../Library/AuthenticatedLayout";
import {useParams} from "react-router-dom";
import {
    CaseAcceptance,
    CustomFormField, GeoCoordinateToString,
    ICase,
    ICaseAnswer,
    ICaseAttachment,
    ICaseComment,
    ICaseDeliverable, ICaseExpense,
    ITimeEntry,
    IUser, PhotoAnswer
} from "../../../types/interfaces";
import {CustomFormFieldSizes, CustomFormFieldTypes, UserRole} from "../../../types/enums";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faPaperPlane, faSave, faTrash,} from "@fortawesome/free-solid-svg-icons";
import {faSpinnerThird} from "@fortawesome/pro-solid-svg-icons";
import {
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {EditObject} from "../../../Shared/Components/EditObject";
import {
    CaseAnswerDataController,
    CaseCommentDataController,
    CaseDataController,
    CaseDeliverablesDataController,
    CaseExpensesDataController,
    CaseTimeEntriesDataController,
    ICaseWithId
} from "../CaseDataController";
import {showAlertAsync} from "../../../Shared/CustomPrompt";
import {UserDataController} from "../../Users/UserDataController";
import {S3GetController} from "../../SurveyAnswers/Components/SurveyAnswerEdit";
import moment from "moment";
import {SiteContactPhonePassThru} from "../../Surveys/SurveyEditor/SiteContactPhonePassThru";
import DebugView from "../../../Library/DebugView";
import useWebSocket from "react-use-websocket";
import {WebSocketServerAction, WebSocketServerMessage} from "../../Surveys/SurveyManagementView";
import {LongFormCaseTextField} from "./LongFormCaseTextField";

/*

    Tasks for this view:
    Finish gathering fields for the case form

 */
const baseURL = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;

function DeliverableView(props: { attachment: ICaseDeliverable /* , onRemoveAttachment: (arg0: ICaseAttachment) => void */ }) {

    let [url, setURL] = React.useState<string | null>(null);

    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;

    React.useEffect(() => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        if (props.attachment.bucket === undefined || props.attachment.key === undefined) {
            return;
        }
        let controller = new S3GetController(userToken);
        controller.getURLWithName(props.attachment.bucket, props.attachment.key, props.attachment.filename).then((url) => {
            setURL(url);
        });
    }, [userToken, props.attachment.bucket, props.attachment.key, props.attachment.filename]);

    return (
        <Paper elevation={4} style={{  border:'1px solid grey', padding: '0.5rem', width: '100%', marginBottom: '0.5rem' }}>
            <Stack direction={"row"} spacing={1} alignItems={'center'}  style={{ width: '100%' }}>
                <Typography style={{fontSize: '1.1em', width: 'calc ( 100% - 4rem )', overflowY: 'auto'}} flexGrow={2}>{props.attachment.filename}</Typography>
                <IconButton disabled={url === null} onClick={ () => {
                    if (url === null) {
                        return;
                    }
                    window.open(url, '_blank');
                } } >
                    <FontAwesomeIcon style={{fontSize: '0.6em'}} icon={faDownload} />
                </IconButton>
            </Stack>

        </Paper>
    )
}

function AttachedMaterialView(props: { attachment: ICaseAttachment, onRemoveAttachment: (arg0: ICaseAttachment) => void }) {

    let [url, setURL] = React.useState<string | null>(null);

    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;

    React.useEffect(() => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        if (props.attachment.bucket === undefined || props.attachment.key === undefined) {
            return;
        }
        let controller = new S3GetController(userToken);
        controller.getURLWithName(props.attachment.bucket, props.attachment.key, props.attachment.filename).then((url) => {
            setURL(url);
        });
    }, [userToken, props.attachment.bucket, props.attachment.key, props.attachment.filename]);


    async function deleteAttachment() {
        if (userToken === undefined || userToken === null) {
            return alert("You must be logged in to upload a photo.");
        }
        let controller = new S3GetController(userToken);

        try {
            await controller.deleteImage(props.attachment.bucket, encodeURIComponent(props.attachment.key));
            props.onRemoveAttachment(props.attachment);
        }
        catch (error: any) {
            console.error('Error:', error);
        }


    }

    return (
        <Paper elevation={4} style={{  border:'1px solid grey', padding: '0.5rem', width: '100%', marginBottom: '0.5rem' }}>
            <Stack direction={"row"} spacing={1} alignItems={'center'}  style={{ width: '100%' }}>

                {/*<FontAwesomeIcon icon={faFile} />*/}
                <Typography style={{fontSize: '1.1em', width: 'calc ( 100% - 4rem )', overflowY: 'auto'}} flexGrow={2}>{props.attachment.filename}</Typography>
                <IconButton disabled={url === null} onClick={ () => {
                    if (url === null) {
                        return;
                    }
                    window.open(url, '_blank');
                } } >
                    <FontAwesomeIcon style={{fontSize: '0.6em'}} icon={faDownload} />
                </IconButton>
                <IconButton color={"error"} onClick={ () => {
                    if (window.confirm(`Are you sure you want to delete '${props.attachment.filename}'?`)) {
                        deleteAttachment().then();
                    }
                } } >
                    <FontAwesomeIcon style={{fontSize: '0.6em'}} icon={faTrash} />
                </IconButton>
            </Stack>

        </Paper>
    )
}

function TimeEntryView(props: { timeEntry: ITimeEntry, usersList: (IUser & {_id: string})[] }) {

    const [ totalTime, setTotalTime ] = React.useState<number | null>(null);
    const [ assignedTech, setAssignedTech ] = React.useState<IUser | null>(null);

    React.useEffect(() => {
        if (props.timeEntry.assignedTech === undefined) {
            return;
        }
        let tech = props.usersList.find((u) => u._id === props.timeEntry.assignedTech);
        if (tech !== undefined) {
            setAssignedTech(tech);
        }
    }, [props.timeEntry.assignedTech, props.usersList]);

    React.useEffect(() => {
        if (props.timeEntry.checkInDate === undefined || props.timeEntry.checkOutDate === undefined) {
            return;
        }
        let diff = moment(props.timeEntry.checkOutDate).diff(moment(props.timeEntry.checkInDate), 'minutes');
        setTotalTime(diff);
    }, [props.timeEntry.checkInDate, props.timeEntry.checkOutDate])

    return (
        <Paper elevation={4} style={{  border:'1px solid grey', padding: '0.5rem', width: '100%', marginBottom: '0.5rem' }}>
            <Stack direction={"row"} spacing={1} alignItems={'center'}  style={{ width: '100%' }}>
                <Stack direction={"column"} spacing={1} alignItems={'start'}  style={{ width: '100%' }}>
                    {/*"YYYY/MM/DD HH:mm:ss"*/}
                    <Typography>{moment(props.timeEntry.checkInDate).format("MM/DD/YYYY HH:mm:ss")}</Typography>
                    <Typography>{ props.timeEntry.checkOutDate !== undefined ? moment(props.timeEntry.checkOutDate).format("MM/DD/YYYY HH:mm:ss") : "Still Checked In"}</Typography>
                </Stack>
                <Stack direction={"column"} spacing={1} alignItems={'start'}  style={{ width: '100%' }}>
                    {/*"YYYY/MM/DD HH:mm:ss"*/}
                    <Typography>{ props.timeEntry.checkInLocation !== undefined ? GeoCoordinateToString(props.timeEntry.checkInLocation) : "Check In Location Not Set"}</Typography>
                    <Typography>{ props.timeEntry.checkOutLocation !== undefined ? GeoCoordinateToString(props.timeEntry.checkOutLocation) : "Check In Location Not Set"}</Typography>
                </Stack>
                <Stack direction={"column"} spacing={1} alignItems={'end'}  style={{ width: '100%' }}>
                    <Typography>{ assignedTech === null ? "Unknown Tech" : assignedTech!.name }</Typography>
                    <Typography>{totalTime ?? 0} minute{ totalTime !== 1 ? "s" : "" }</Typography>
                </Stack>
            </Stack>
        </Paper>
    )
}

function CaseExpenseView(props: { caseExpense: ICaseExpense }) {

    const {
        userToken
    } = React.useContext(AuthContext)!;

    const [photoURLs, setPhotoURLs] = React.useState<string[]>([]);

    React.useEffect(() => {

        // let photoAnswer: PhotoAnswer = answer as PhotoAnswer;
        // if (photoAnswer === undefined) {
        //     photoAnswer = {
        //         photo: {
        //             data: [ ] }
        //     } as PhotoAnswer;
        // }


        if (userToken === undefined || userToken === null) { return }
        let controller = new S3GetController(userToken);
        const fetchPhotoURLs = async () => {
            const urls = await Promise.all(
                props.caseExpense.receipts.map(async(receipt) => {
                    return await controller.getURL(receipt.bucket, receipt.key);
                })
                //
                // photoAnswer.photo.data.map(async (photo) => {
                //     return await controller.getURL(photo.bucket, photo.key);
                // })
            );
            setPhotoURLs(urls);
        };

        fetchPhotoURLs().then();
    }, [props.caseExpense, userToken]);

    return (
        <Paper elevation={4} style={{  border:'1px solid grey', padding: '0.5rem', width: '100%', marginBottom: '0.5rem' }}>
            <Stack direction={"column"} spacing={1} alignItems={'center'}
                   style={{width: '100%'}}>
                <p> ${props.caseExpense.amount} {props.caseExpense.category} {props.caseExpense.notes} </p>
                {/*<DebugView value={photoURLs} />*/}
                {photoURLs.map((url, index) => (
                    <div key={index} style={{ width: '300px' }} >
                        <img
                            src={url}
                            alt={`${index}`}
                            style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                        />
                    </div>
                ))}
            </Stack>
        </Paper>
    )
}

// enum WebSocketServerAction {
//     NOOP = "NOOP",
//     ERROR = "ERROR",
//     NewReportGenerated = "NewReportGenerated",
//     CaseCommentCreated = "CaseCommentCreated"
// }

function CaseView() {

    const {
        user,
        userToken
    } = React.useContext(AuthContext)!;

    const { id } = useParams<{ id: string }>();

    const [usersList, setUserList] = React.useState<(IUser & {_id: string})[]>([])
    const [coordinatorsList, setCoordinatorList] = React.useState<(IUser & {_id: string})[]>([])


    const [caseComments, setCaseComments] = React.useState<ICaseComment[]>([])
    const [sendingCaseComment, setSendingCaseComment] = React.useState<boolean>(false);


    const [caseDeliverables, setCaseDeliverables] = React.useState<ICaseDeliverable[]>([])
    const [timeEntries, setTimeEntries] = React.useState<ITimeEntry[]>([])
    const [caseExpenses, setCaseExpenses] = React.useState<ICaseExpense[]>([])

    const [caseItem, internal_setCaseItem] = React.useState<ICaseWithId>()
    const [saveRequired, setSaveRequired] = React.useState<boolean>(false);
    const setCaseItem = React.useCallback((item: ICaseWithId) => {
        console.log(item)
        internal_setCaseItem(item);
        setSaveRequired(true);
    }, [internal_setCaseItem]);
    React.useEffect(() => {
        window.onbeforeunload = saveRequired
            ? (event: BeforeUnloadEvent) => {
                return "Please save before closing the page";
            }
            : null;
    }, [saveRequired]);

    const [caseAnswerItem, setCaseAnswerItem] = React.useState<ICaseAnswer>()

    const fields = React.useMemo(
        () => {
            let fields: CustomFormField[] = [
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.FULL,
                    key: "caseNumber",
                    label: "Case Number"
                },
                {
                    kind: CustomFormFieldTypes.SELECT_DROPDOWN,
                    size: CustomFormFieldSizes.SMALL,
                    key: "assigned_user_id",
                    label: "Assigned User Id",
                    options: [
                        ...usersList.map((opt: (IUser & {_id: string})) => { return { label: opt.name, value: opt._id }; })
                    ],
                },
                {
                    kind: CustomFormFieldTypes.SELECT_DROPDOWN,
                    size: CustomFormFieldSizes.SMALL,
                    key: "coordinator_user_id",
                    label: "Coordinator",
                    options: [
                        ...coordinatorsList.map((opt: (IUser & {_id: string})) => { return { label: opt.name, value: opt._id }; })
                    ]
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.TWO,
                    key: "siteContactName",
                    label: "Site Contact Name"
                },
                {
                    kind: CustomFormFieldTypes.PASSTHRU,
                    size: CustomFormFieldSizes.TWO,
                    key: "siteContactPhone",
                    label: "siteContactPhone",
                    passthru: SiteContactPhonePassThru,
                    passthruProps: { survey: caseItem, setSurvey: setCaseItem }
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.TWO,
                    key: "siteContactEmail",
                    label: "Site Contact Email"
                },
                {
                    kind: CustomFormFieldTypes.PASSTHRU,
                    size: CustomFormFieldSizes.TWO,
                    key: "address",
                    label: "Address",
                    passthru: LongFormCaseTextField,
                    passthruProps: { caseItem: caseItem, setCase: setCaseItem }
                },

                {
                    kind: CustomFormFieldTypes.DATETIME,
                    size: CustomFormFieldSizes.ONE,
                    key: "serviceDateTime",
                    label: "Service Date Time"
                },
                {
                    kind: CustomFormFieldTypes.DATETIME,
                    size: CustomFormFieldSizes.ONE,
                    key: "serviceDateTimeEnd",
                    label: "Service Date Time End"
                },

                {
                    kind: CustomFormFieldTypes.DATETIME,
                    size: CustomFormFieldSizes.TWO,
                    key: "slaDueDateTime",
                    label: "SLA Due Date Time"
                },

                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.TWO,
                    key: "customerTicketNumber1",
                    label: "Customer Ticket Number 1"
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.TWO,
                    key: "customerTicketNumber2",
                    label: "Customer Ticket Number 2"
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.TWO,
                    key: "caseSubject",
                    label: "Case Subject"
                },

                {
                    kind: CustomFormFieldTypes.TEXTAREA,
                    size: CustomFormFieldSizes.HALF,
                    key: "serviceDescription",
                    label: "Service Description",
                    multilineRows: 20
                    // passthru: InLineLongFormCaseTextField,
                    // passthruProps: { caseItem: caseItem, setCase: setCaseItem }
                },
                {
                    kind: CustomFormFieldTypes.TEXTAREA,
                    size: CustomFormFieldSizes.HALF,
                    key: "specificInstructions",
                    label: "Specific Instructions",
                    multilineRows: 20
                    // passthru: InLineLongFormCaseTextField,
                    // passthruProps: { caseItem: caseItem, setCase: setCaseItem }
                },

                // {
                //     kind: CustomFormFieldTypes.PASSTHRU,
                //     size: CustomFormFieldSizes.TWO,
                //     key: "serviceDescription",
                //     label: "Service Description",
                //     // multilineRows: 20
                //     passthru: LongFormCaseTextField,
                //     passthruProps: { caseItem: caseItem, setCase: setCaseItem }
                // },
                // {
                //     kind: CustomFormFieldTypes.PASSTHRU,
                //     size: CustomFormFieldSizes.TWO,
                //     key: "specificInstructions",
                //     label: "Specific Instructions",
                //     // multilineRows: 20
                //     passthru: LongFormCaseTextField,
                //     passthruProps: { caseItem: caseItem, setCase: setCaseItem }
                // },


            ];

            // TODO: finish filling out the case Fields.
            return fields;
        },
        [setCaseItem, caseItem, coordinatorsList, usersList]
    );

    const loadData = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        if (id === null || id === undefined) {
            return;
        }
        try {
            let item = await new CaseDataController(userToken).getOne(id);
            internal_setCaseItem(item)

            let answer = await new CaseAnswerDataController(userToken).getOne(item.caseNumber);
            setCaseAnswerItem(answer)

            let userList = await new UserDataController(userToken).getAll();
            if (userList !== null && userList !== undefined) {
                setUserList(userList.filter((u) => u.role !== UserRole.CLIENT).sort((a, b) => a.name.localeCompare(b.name)));
                setCoordinatorList(userList.filter((u) =>  u.role === UserRole.USER_MANAGER || u.role === UserRole.MANAGER || u.role === UserRole.ADMIN).sort((a, b) => a.name.localeCompare(b.name)));
            }


            let comments = await new CaseCommentDataController(userToken).getOne(id);
            if (comments !== null && comments !== undefined) {
                setCaseComments(comments);
            }

            let deliverables = await new CaseDeliverablesDataController(userToken).getOne(id);
            if (deliverables !== null && deliverables !== undefined) {
                setCaseDeliverables(deliverables);
            }

            let timeEntries = await new CaseTimeEntriesDataController(userToken).getOne(id);
            if (timeEntries !== null && timeEntries !== undefined) {
                setTimeEntries(timeEntries);
            }

            let tempExpenses = await  new CaseExpensesDataController(userToken).getOne(id);
            if (tempExpenses !== null && tempExpenses !== undefined) {
                setCaseExpenses(tempExpenses)
            }

        } catch (e) {
            console.warn(`exception: ${(e as any).message}`)
        }


    }, [id, userToken])

    React.useEffect(() => {
        loadData().then()
    }, [id, userToken, loadData])

    const handleSave = React.useCallback(async () => {
        if (caseItem === null || caseItem === undefined) {
            console.warn("handleSave called with caseItem undefined or null")
            return
        }
        if (userToken === null || userToken === undefined) {
            console.warn("handleSave called with userToken undefined or null")
            return;
        }
        if (id === null || id === undefined) {
            console.warn("handleSave called with id undefined or null")
            return;
        }

        try {
            console.log(caseItem)
            let response = await new CaseDataController(userToken).update(id, caseItem);
            console.log(response);
            if (response === null || response === undefined) {
                alert("Error saving");
                return;
            }
            internal_setCaseItem(response); // setting the values to this, causes the fields to go into a weird mode.
            setSaveRequired(false);
            await showAlertAsync({title: "Case Saved", message:"Saved Successfully", timeout: 2}).then();
        } catch (e) {
            console.warn(`exception: ${(e as any).message}`)
        }

    }, [id, userToken, caseItem, internal_setCaseItem, setSaveRequired])

    const customButtonsMemo = React.useMemo<AuthenticatedLayoutCustomButton[]>(() => {
        let buttons: AuthenticatedLayoutCustomButton[] = [];

        if (saveRequired) {
            // Save button
            buttons.push({
                icon:  <FontAwesomeIcon icon={faSave} />,
                label: "Save",
                action: () => {
                    console.log("save clicked")
                    handleSave().then(() => {})
                }
            });

            // Save button
            buttons.push({
                icon:  <FontAwesomeIcon icon={faTrash} />,
                label: "Don't Save",
                action: () => {
                    setSaveRequired(false)
                }
            });
        }



        return buttons;

    }, [ saveRequired, handleSave ]);


    const [newComment, setNewComment] = React.useState<string>("");
    const [clientFacing, setClientFacing] = React.useState<boolean>(false);
    const sendCaseComment = React.useCallback(async (comment: string) => {
        if (userToken === null || userToken === undefined) {
            console.warn("sendCaseComment called with userToken undefined or null")
            return;
        }
        if (id === null || id === undefined) {
            console.warn("sendCaseComment called with id undefined or null")
            return;
        }

        try {
            if (user === null || user === undefined) {
                console.warn("sendCaseComment called with user undefined or null")
                return;
            }

            let newComment: ICaseComment = {
                created_by: user?._id,
                caseItem: id,
                content: comment,
                clientFacing: clientFacing
            };
            let response = await new CaseCommentDataController(userToken).create(id, newComment);
            console.log(response);
            if (response === null || response === undefined) {
                alert("Error saving");
                return;
            }
            // push to the caseComments array
            let comments = await new CaseCommentDataController(userToken).getOne(id);
            if (comments !== null && comments !== undefined) {
                setCaseComments(comments);
            }
            setNewComment("");
        } catch (e) {
            console.warn(`exception: ${(e as any).message}`)
        }
    }, [id, user, userToken]);

    const processMessages = React.useCallback(async (event: WebSocketEventMap['message']) => {
        if (userToken === null || userToken === undefined) {
            console.warn("sendCaseComment called with userToken undefined or null")
            return;
        }
        if (id === null || id === undefined) {
            console.warn("sendCaseComment called with id undefined or null")
            return;
        }
        console.log("Received message: ", event.data);
        let data = JSON.parse(event.data) as WebSocketServerMessage;
        console.log("Received message: ", data);
        switch (data.action) {

            case WebSocketServerAction.NOOP:
                break;
            case WebSocketServerAction.ERROR:
                console.error("Error: ", data);
                alert(data.data)
                break;
            case WebSocketServerAction.NewReportGenerated:
                // alert("New report generated")
                // UpdateList().then();
                // TODO: do this more directly with the row id
                break;
            case WebSocketServerAction.CaseCommentCreated:
                let comments = await new CaseCommentDataController(userToken).getOne(id);
                console.log(comments)
                if (comments !== null && comments !== undefined) {
                    setCaseComments(comments); // TODO: this isn't setting it on the screen?!?!
                    console.log("set comments")

                }
                else {
                    console.log("broken")
                }
                break;
        }
    }, [])

    // sendJsonMessage
    useWebSocket(WS_URL + '/?token=' + encodeURIComponent(userToken ?? ""), {
        onOpen: () => console.log('WebSocket connection opened.'),
        onClose: () => console.log('WebSocket connection closed.'),
        shouldReconnect: (closeEvent) => true,
        onMessage: (event: WebSocketEventMap['message']) =>  processMessages(event)
    });


    const uploadAttachment = React.useCallback(async () => {
        if (userToken === undefined || userToken === null || user === undefined || user === null) {
            return alert("You must be logged in to upload an attachment.");
        }

        if (id === null || id === undefined) {
            console.warn("sendCaseComment called with id undefined or null")
            return;
        }
        if (caseItem === null || caseItem === undefined) {
            console.warn("sendCaseComment called with caseItem undefined or null")
            return;
        }

        let controller = new S3GetController(userToken);

        function getFileExtension(filename: string) {
            return filename.split('.').pop();
        }

        let input = document.createElement('input');
        input.type = 'file';
        input.onchange = async (event: any) => {

            let files = event.target.files;

            const reader = new FileReader();
            reader.onload = async (e) => {
                console.warn(e);
                let name = files[0].name;
                // let name = event.target.files[0].name;
                let extension = getFileExtension(name);
                let mimeType = files[0].type;
                if (e.target && e.target.result && extension !== undefined) {
                    const fileContent = e.target.result as ArrayBuffer;

                    let uploadInfo = await controller.createURLForAttachment(id, extension);
                    console.log(uploadInfo)

                    let caseItemToUpdate: ICase = { ...caseItem };
                    if (caseItemToUpdate.attachedMaterials === undefined) {
                        caseItemToUpdate.attachedMaterials = [];
                    }

                    let attachedMaterial = {
                        createdAt: new Date(),
                        createdBy: user?._id,
                        bucket: uploadInfo.bucket,
                        key: uploadInfo.key,
                        filename: name
                    } as ICaseAttachment

                    let newMaterials = [...caseItem.attachedMaterials, attachedMaterial];
                    let newCaseItem = {...caseItem, attachedMaterials: newMaterials};

                    let response = await new CaseDataController(userToken).update(id, newCaseItem);
                    internal_setCaseItem(response);

                    await controller.uploadFile(uploadInfo.url, new File([fileContent], name, { type: mimeType }))
                }
            };

            reader.readAsArrayBuffer(event.target.files[0]);
        };
        input.click();

    }, [userToken, caseItem, id, user]);

    return (
        <div>
            {/* move the sidebar stuff into a provider system. */}
            <AuthenticatedLayout
                                  pageTitle={"Case Editor" + (saveRequired ? "*" : "")}
                                  saveRequired={saveRequired}
                                  customButtons={customButtonsMemo}
            >

                <Stack sx={{ height: '100%', overflowY: 'scroll'}}>
                    <EditObject item={caseItem} setItem={setCaseItem} form={fields} columns={12}></EditObject>



                    <Stack direction={"row"} spacing={2}>
                        {caseAnswerItem !== undefined && caseAnswerItem !== null && (
                            <Paper elevation={2} variant={"elevation"} style={{ border:'1px solid rgba(230, 81, 0, 0.5)', width:"300px", height: '400px', padding: '0.5rem' }} >
                                <Stack style={{ width:"100%", height:'100%' }}>
                                    <Typography variant={"h6"} style={{ textAlign: 'center' }}>Details</Typography>
                                    <hr style={{ width: "100%", marginTop: 0 }} />
                                    <Stack style={{ height: 'calc( 100% - 1rem )', paddingBottom: '0.25rem', overflowY: 'scroll' }} spacing={1}>

                                        <Typography variant={"body1"} style={{ textAlign: 'center' }}>Case Acceptance:  {JSON.stringify(caseAnswerItem.caseAcceptance)}</Typography>
                                        {caseAnswerItem.caseAcceptance === CaseAcceptance.Decline && (
                                            <Typography variant={"body1"} style={{ textAlign: 'center' }}>Case Declined:  {JSON.stringify(caseAnswerItem.caseDeclinedReason)}</Typography>
                                        )}


                                        {caseAnswerItem.caseAcceptance === CaseAcceptance.Confirm && (
                                           <>
                                               <Typography variant={"body1"} style={{ textAlign: 'center' }}>On My Way: {JSON.stringify(caseAnswerItem.onMyWayTimestamp)}</Typography>
                                               <Typography variant={"body1"} style={{ textAlign: 'center' }}>ETA:  {JSON.stringify(caseAnswerItem.etaTimestamp)}</Typography>


                                               <Typography variant={"body1"} style={{ textAlign: 'center' }}>Closed:  {JSON.stringify(caseAnswerItem.isCaseClosed)}</Typography>

                                               <Typography variant={"body1"} style={{ textAlign: 'center' }}>Closing Notes:  {JSON.stringify(caseAnswerItem.closingNotes)}</Typography>

                                           </>
                                        )}

                                        <Typography variant={"body1"} style={{ textAlign: 'center' }}>RMA:  {JSON.stringify(caseAnswerItem.returnMerchandiseApprovals)}</Typography>

                                    </Stack>
                                </Stack>
                            </Paper>
                        )}

                        <Paper elevation={2} variant={"elevation"} style={{ border:'1px solid rgba(230, 81, 0, 0.5)', width:"300px", height: '400px', padding: '0.5rem' }} >
                            <Stack style={{ width:"100%", height:'100%' }}>
                                <Typography variant={"h6"} style={{ textAlign: 'center' }}>Comments</Typography>
                                <hr style={{ width: "100%", marginTop: 0 }} />
                                <Stack style={{ height: 'calc( 100% - 1rem )', paddingBottom: '0.25rem', overflowY: 'scroll' }} spacing={1}>
                                    {caseComments.length > 0 && caseComments.map((comment, index) => {
                                        return (
                                            <Stack direction={"row"} style={{ width: '100%' }}>
                                                {comment.created_by === user?._id && <div style={{ flexGrow: 2 }}></div>}
                                                <Paper elevation={4} style={{  border:'1px solid rgb(214, 235, 242)', padding: '0.5rem' }}>

                                                    <Typography>{comment.clientFacing ? "Client Facing" : "Not Client Facing"}</Typography>
                                                    <Typography>{comment.content}</Typography>
                                                </Paper>
                                                {comment.created_by !== user?._id && <div style={{ flexGrow: 2 }}></div>}
                                            </Stack>
                                        )
                                    })}
                                </Stack>

                                <FormControlLabel
                                    label="Client Facing"
                                    control={
                                        <Checkbox
                                            checked={clientFacing}
                                            onChange={(e) => setClientFacing(e.target.checked)}
                                            inputProps={{ 'aria-label': 'Client Facing' }}
                                        />
                                    }
                                />

                                <TextField
                                    style={{ border: '1px solid rgb(230, 81, 0)' }}
                                    fullWidth={true}
                                    placeholder={"Message"}
                                    value={newComment}
                                    onChange={(e) => setNewComment(e.target.value)}
                                    InputProps={
                                        {
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton onClick={ () => {
                                                        if (newComment === "") {
                                                            return;
                                                        }
                                                        setSendingCaseComment(true);
                                                        sendCaseComment(newComment).then(() => {
                                                            setSendingCaseComment(false);
                                                        })
                                                    } } disabled={newComment.length === 0 || sendingCaseComment}>
                                                        <FontAwesomeIcon style={{fontSize: '0.6em'}} icon={sendingCaseComment ?  faSpinnerThird :   faPaperPlane} spin={sendingCaseComment} />
                                                    </IconButton>
                                                </InputAdornment>
                                        }
                                    }
                                />

                            </Stack>
                        </Paper>

                        <Paper elevation={2} variant={"elevation"} style={{ border:'1px solid rgba(230, 81, 0, 0.5)', width:"500px", height: '400px', padding: '0.5rem' }} >
                            <Stack style={{ width:"100%", height:'100%' }}>
                                <Typography variant={"h6"} style={{ textAlign: 'center' }}>Attached Materials</Typography>
                                <hr style={{ width: "100%", marginTop: 0 }} />
                                <div style={{ height: 'calc( 100% - 1rem )', width: '100%', overflowY: 'scroll' }}>

                                    {caseItem?.attachedMaterials !== undefined && caseItem?.attachedMaterials.map((attachment, index) => {
                                        return (
                                            <AttachedMaterialView attachment={attachment} onRemoveAttachment={(attachmentToRemove) => {
                                                let newMaterials = caseItem.attachedMaterials.filter((a) => a !== attachmentToRemove);
                                                let newCaseItem = {...caseItem, attachedMaterials: newMaterials};
                                                setCaseItem(newCaseItem);
                                            }} />
                                        )
                                    })}
                                </div>

                                <Button style={{ padding: '0.5rem' }} variant={"outlined"} onClick={() => {
                                    uploadAttachment().then();
                                }} disabled={false}>
                                        <Typography style={{ textAlign: 'center' }}>Add Attachment</Typography>
                                </Button>
                            </Stack>
                        </Paper>

                        <Paper elevation={2} variant={"elevation"} style={{ border:'1px solid rgba(230, 81, 0, 0.5)', width:"500px", height: '400px', padding: '0.5rem' }} >
                            <Stack style={{ width:"100%", height:'100%' }}>
                                <Typography variant={"h6"} style={{ textAlign: 'center' }}>Deliverables</Typography>
                                <hr style={{ width: "100%", marginTop: 0 }} />
                                <div style={{ height: 'calc( 100% - 1rem )', width: '100%', overflowY: 'scroll' }}>

                                    {caseDeliverables !== undefined && caseDeliverables.map((attachment, index) => {
                                        return (
                                            <DeliverableView attachment={attachment} />
                                        )
                                    })}
                                </div>
                            </Stack>
                        </Paper>

                        <Paper elevation={2} variant={"elevation"} style={{ border:'1px solid rgba(230, 81, 0, 0.5)', width:"500px", height: '400px', padding: '0.5rem' }} >
                            <Stack style={{ width:"100%", height:'100%' }}>
                                <Typography variant={"h6"} style={{ textAlign: 'center' }}>Time Entries</Typography>
                                <hr style={{ width: "100%", marginTop: 0 }} />
                                <div style={{ height: 'calc( 100% - 1rem )', width: '100%', overflowY: 'scroll' }}>
                                    {timeEntries !== undefined && timeEntries.map((timeEntry, index) => {
                                        return (
                                            <TimeEntryView timeEntry={timeEntry} usersList={usersList} />
                                        )
                                    })}
                                </div>
                            </Stack>
                        </Paper>



                        <Paper elevation={2} variant={"elevation"} style={{ border:'1px solid rgba(230, 81, 0, 0.5)', width:"500px", height: '400px', padding: '0.5rem' }} >
                            <Stack style={{ width:"100%", height:'100%' }}>
                                <Typography variant={"h6"} style={{ textAlign: 'center' }}>Expenses</Typography>
                                <hr style={{ width: "100%", marginTop: 0 }} />
                                <div style={{ height: 'calc( 100% - 1rem )', width: '100%', overflowY: 'scroll' }}>
                                    {caseExpenses !== undefined && caseExpenses.map((expense, index) => {
                                        return (
                                            <CaseExpenseView caseExpense={expense} />
                                        )
                                    })}
                                </div>
                            </Stack>
                        </Paper>


                    </Stack>
                </Stack>
            </AuthenticatedLayout>
        </div>
    );
}

export default CaseView;
